module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ULTRASANO ambulanta za ultrazvuk","short_name":"ULTRASANO","start_url":"/","background_color":"#fff","theme_color":"#02aab0","display":"standalone","icon":"src/images/Ultrasano_Logo_Full_Color.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3eb6af0fac1087e93056acf6b9a2c03"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
